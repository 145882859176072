import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
  Home: "/nl",
  "Over mij": "/nl/over"
};

// Hreflang data

const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/about"
  },
  {
    hreflang: "de",
    href: "/de/ueber-mich"
  },
  {
    hreflang: "se",
    href: "/se/om"
  },
  {
    hreflang: "da",
    href: "/da/om-mig"
  },
  {
    hreflang: "no",
    href: "/no/om"
  },
  {
    hreflang: "nl",
    href: "/nl/over"
  },
  {
    hreflang: "x-default",
    href: "/en/about"
  }
];

const About = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO title="Over Matthias Kupperschmidt" lang="nl" alternateLangs={alternateLangs} />
      <MainContent maxWidth="700">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <div className="content">
              <H as="h1">Hoi, ik ben Matthias</H>
              <p>Ik optimaliseer websites voor de zoekresultaten van Google en implementeer analytische setups.</p>
              <p>
                Ik ben Duits en woon in Kopenhagen, Denemarken. Ik heb {experienceYears} jaar bij meerdere digitale bureaus gewerkt
                voordat ik begon als <Link to="/nl/google-analytics-freelancer">freelancer voor Google Analytics</Link> en SEO.
              </p>
              <p>
                Volgens verschillende persoonlijkheidstests ben ik zeer analytisch, rationeel en gestructureerd, terwijl ik tegelijkertijd
                doelgericht en pragmatisch ben.
              </p>
              <H as="h2">Mijn verhaal</H>
              <p>
                Op mijn 14e bouwde ik mijn eerste website om films te ruilen op het schoolplein. Het was de beste manier om mijn
                filmcollectie aan mijn klasgenoten te laten zien. Ik herinner me dat ik de eerste html's maakte met Microsoft Word en later
                met een editor genaamd "Phase5".
              </p>
              <p>
                Later bouwde ik mijn eigen e-commerce webshop voor tafelvoetbal (of "fooseball" zoals wij het noemen), genaamd
                "Kickerkult". Ik was 18 en een aspirant, wannabe-professionele tafelvoetballer, dus het was een goede
                combinatie van twee interesses die ik had.
                <br /> In die tijd speelde ik veel toernooien in Berlijn en door heel Duitsland. Ondertussen beheerde ik
                mijn webshop, leerde ik e-mailnieuwsbrieven versturen en begon ik met SEO.
                <br />
                Uiteindelijk raakte ik enigszins verslaafd aan het web en de mogelijkheden ervan en wilde ik mijn carrière daarop richten.
              </p>
              <p>
                Vandaag de dag help ik bedrijven van verschillende groottes om bovenaan in de zoekresultaten van Google te komen en
                hun gebruikersgedrag te volgen - ongeacht waar ter wereld het bedrijf zich bevindt.
              </p>
              <p>
                Daarnaast schrijf ik over SEO en Analytics onderwerpen op mijn <Link to="/nl/blog">blog</Link>.
              </p>
              <H as="h2">
                Welke stad is de volgende Silicon Valley?
                <br />
                De jouwe!
              </H>
              <p>
                Ik geloof sterk dat je niet naar de grote, glorieuze steden hoeft te verhuizen om een geweldige carrière te bereiken.
                Via het internet vinden moderne bedrijven, professionals en gelijkgestemden elkaar en werken samen - ongeacht de geografische afstand.
              </p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">Laten we in contact blijven!</div>
          <div className="serif">
            Maak je geen zorgen, ik zal je nauwelijks e-mailen. Schrijf je hier in om zeker te zijn dat je iets belangrijks hoort wanneer ik het te delen heb.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
          { }
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>

  </Layout>
);


export default React.memo(About);
